<template>
    <div id="profile" class="page">
        <h1 class="page-title">Hello i’m <br> <b>Carlos Gamez</b> </h1>
        <h3 class="page-excerpt">And i like to build digital experiences for humans</h3>
        <p class="page-paragraph">
            I'm a programmer and UI designer with more than 5 years of experience creating websites 
            for businesses around the world and helping creative teams to achieve their goals and making their UI creative visions come true. 
            <br>
            In addition to web development, I'm a great enthusiast of video game programming, user experience and 3D art, for this reason I really 
            enjoy the challenge of creating three-dimensional interfaces that interact with the user, I want to help push the boundaries of 
            user experience and make the web an even more diverse and interesting experience.
        </p>
        <div>
            <h2 class="page-sub-title">Certifications</h2>
            <p class="page-paragraph">
                I graduated as a Systems Engineer in 2016 but I always try to keep studying and learning something new, 
                you can check some of my latest certifications in the link below.
            </p>
            <router-link class="page-link" to="/profile/certifications">Click Here</router-link>
            <h2 class="page-sub-title">Other Links of Interest</h2>
            <p class="page-paragraph">
                If you are interested in knowing more about me and my work, these links will take you to other related sites.
            </p>
            <!-- <a href="" class="page-link">Behance</a>
            <br>
            <br> -->
            <a href="https://dribbble.com/carlosgarts" class="page-link">Dribble</a>
        </div>
    </div>
</template>
<script>


export default {
    name: 'Profile',
    mounted () {
        window.scrollTo(0, 0)
    }
    
}
</script>
<style lang="scss">
    
</style>
